
// Services
import { useAdministrator } from "@/services";
// Interfaces
import { IRecoverPassword } from "@/store/administrative/users/usersState";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import VonaLogo from "@core/layouts/components/Logo.vue";
import { required } from "@validations";
import {
  BButton,
  BCard,
  BCardText,
  BCardTitle,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Component, Vue } from "vue-property-decorator";
import { RouterLink } from "vue-router";

@Component({
  components: {
    VonaLogo,
    BCard,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    RouterLink,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
})
export default class ResetPassword extends Vue {
  // Data
  $refs = {
    simpleRules: {} as any,
  };
  userEmail: string = "";
  cPassword: string = "";
  password: string = "";
  // validation
  required = required;

  // Toggle Password
  password1FieldType: string = "password";
  password2FieldType: string = "password";

  // Computeds
  get password1ToggleIcon(): string {
    return this.password1FieldType === "password" ? "EyeIcon" : "EyeOffIcon";
  }

  get password2ToggleIcon(): string {
    return this.password2FieldType === "password" ? "EyeIcon" : "EyeOffIcon";
  }

  get getAssociation(): string {
    return localStorage.getItem("domain") || "Vona";
  }

  // Methods
  togglePassword1Visibility() {
    this.password1FieldType =
      this.password1FieldType === "password" ? "text" : "password";
  }

  togglePassword2Visibility() {
    this.password2FieldType =
      this.password2FieldType === "password" ? "text" : "password";
  }

  validationForm() {
    this.$refs.simpleRules.validate().then((success: boolean) => {
      if (success) {
        const recoverInfo: IRecoverPassword =
          this.$store.getters["admin-users/GET_RECOVER_PASSWORD"];

        useAdministrator
          .requestSaveNewPassword({
            codigo: recoverInfo.code,
            email: recoverInfo.email,
            novaSenha: this.password,
          })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Senha redefinida com sucesso!",
                icon: "EditIcon",
                variant: "success",
              },
            });

            return this.$router.push({
              name: "auth-login",
              query: this.$route.query,
            });
          })
          .catch((error: any) => {
            let title = "Não foi possível redefinir sua senha";
            if (error.response.data?.errors) {
              if (error.response.data.errors.length > 0) {
                title = error.response.data.errors[0];
              }
            }

            this.$toast({
              component: ToastificationContent,
              props: {
                title: title,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
      }
    });
  }
}
